export function createTypes(base: string, actionsArray: string[]) {
  const res: { [x: string]: string } = {};
  actionsArray.forEach(type => {
    res[type] = `${base}_${type}`;
  });
  return res;
}

export function parseSnapshotToRedux<T>(
  snapshot: QueryDocumentSnapshot<T> | null
): FirestoreObject<T> {
  return {
    data: snapshot && snapshot.data(),
    ref: snapshot ? snapshot.ref : undefined
  };
}
