import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import theme from '../theme';
import logo from '../common/assets/logo.svg';
import { getCurrentHeating } from './home.ducks';
import AddWoodInput from './steps/AddWoodInput';
import StartHeatingInput from './steps/StartHeatingInput';
import COSteamInput from './steps/COSteamInput';
import WashInput from './steps/WashInput';
import BathInput from './steps/BathInput';

const ActionInput: React.FC = ({ ...rest }) => {
  const currentHeating = useSelector(getCurrentHeating);

  // update clock in every 15 seconds
  const [seconds, setSeconds] = useState(new Date().getTime() / 1000);
  useEffect(() => {
    const interval = setInterval(() => setSeconds(new Date().getTime() / 1000), 15 * 1000);
    return () => clearInterval(interval);
  });

  const latestStep =
    currentHeating &&
    currentHeating.steps.length > 0 &&
    currentHeating.steps.sort((a, b) => b.time.seconds - a.time.seconds)[0];
  const latestTemp = latestStep && latestStep.details && (latestStep.details.temp as number | null);
  const latestTime = latestStep && new Date(latestStep.time.seconds * 1000);
  const latestPhase = latestStep && latestStep.phase;

  const timeToNext =
    currentHeating && latestStep && latestStep.nextNotif
      ? Math.round(
          (currentHeating.lastModified.seconds + latestStep.nextNotif.minutes * 60 - seconds) / 60
        )
      : null;

  return (
    <Wrapper {...rest}>
      <LogoImg src={logo} alt="Sauna logo" />
      {currentHeating !== undefined && (
        <Background>
          {latestPhase !== 'bath'
            ? timeToNext !== null && (
                <TimeText>{timeToNext === 0 ? 'Nyt' : `${timeToNext} min`}</TimeText>
              )
            : latestTime && (
                <TimeText>
                  {latestTime.getHours()}:{latestTime.getMinutes()}
                </TimeText>
              )}
          {(latestTemp || latestTemp === 0) && <TempText>{latestTemp} °C</TempText>}
          {!latestPhase ? <StartHeatingInput /> : null}
          {latestPhase === 'start' || latestPhase === 'add' ? (
            <AddWoodInput latestTemp={latestTemp} />
          ) : null}
          {latestPhase === 'finish1' ? <COSteamInput /> : null}
          {latestPhase === 'finish2' ? <WashInput /> : null}
          {latestPhase === 'finish3' ? <COSteamInput second /> : null}
          {latestPhase === 'finish4' ? <BathInput /> : null}
          {latestPhase === 'bath' ? <p>Hyviä saunomisia &#128293;</p> : null}
        </Background>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
`;

const LogoImg = styled.img`
  height: 250px;
  z-index: 100;
`;

const InfoText = styled.p`
  font-size: 14px;
  position: absolute;
  top: 10px;
`;
const TimeText = styled(InfoText)`
  left: 10px;
`;
const TempText = styled(InfoText)`
  right: 10px;
`;

const Background = styled.div`
  background: ${theme.color.gray[600]};
  color: ${theme.color.gray[900]};
  font-size: 24px;
  padding: 20px;
  padding-top: 80px;
  margin-top: -70px;
  border-radius: 10px;
  position: relative;
  width: 300px;
  text-align: center;
`;

export default ActionInput;
