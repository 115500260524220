import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDxRl33yHmiH_6UsaKbjyg6nLzQN6Bi9wg',
  authDomain: 'sauna-f6b8c.firebaseapp.com',
  databaseURL: 'https://sauna-f6b8c.firebaseio.com',
  projectId: 'sauna-f6b8c',
  storageBucket: '',
  messagingSenderId: '387535030145',
  appId: '1:387535030145:web:6f02f75f4450a66e'
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export const messaging = firebase.messaging();
messaging.usePublicVapidKey(
  'BJ6stTMRj24zv_RQilfUlAE8MPTix25LTY-4Qz9fxp4qwrnpUzHEmFlh69LXdIXRjuE0wnX3HVWZbYGidS956-A'
);

messaging.onMessage(payload => {
  console.log('Message received. ', payload);

  /* const notificationTitle = payload.data.instruction;
  const notificationOptions = {
    body: payload.data.time,
    icon: './logo.png'
  };

  return new Notification(notificationTitle, notificationOptions); */
});
