import { createStore, combineReducers, applyMiddleware } from 'redux';
import { fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import appReducer, { AppState } from './app/app.ducks';
import homeReducer, { HomeState, homeSagas } from './home/home.ducks';

export interface ReduxState {
  app: AppState;
  home: HomeState;
}

const rootReducer = combineReducers({
  app: appReducer,
  home: homeReducer
});

function* rootSaga() {
  yield fork(homeSagas);
}

export default function configStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, undefined, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);
  return store;
}
