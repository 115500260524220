import React, { useRef } from 'react';
import styled from 'styled-components';

interface Props extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: string | number;
  onChange: (value: string) => void;
}

const NumberInput: React.FC<Props> = ({ value, onChange, ...rest }: Props) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (inputEl.current) inputEl.current.blur();
    }
  };

  return (
    <Input
      ref={inputEl}
      {...rest}
      type="text"
      inputMode="numeric"
      value={value !== undefined ? value : ''}
      onChange={e => onChange(e.target.value)}
      onKeyDown={handleKeyDown}
    />
  );
};

const Input = styled.input`
  background: no-repeat;
  border: none;
  border-bottom: 1px solid currentColor;
  font-size: 1em;
  width: 60px;

  &:focus {
    outline: none;
  }
`;

export default NumberInput;
