import { createAction, handleActions, Action } from 'redux-actions';
import { takeEvery, all, select, put, take } from 'redux-saga/effects';
import update from 'immutability-helper';
import firebase from 'firebase/app';

import { createTypes, parseSnapshotToRedux } from '../common/utils/redux.util';
import { ReduxState } from '../store';
import { getUserRef } from '../app/app.ducks';

export interface HomeState {
  currentHeating: FirestoreObject<Heating>;
}

//Action types
export const HOME = createTypes('HOME', [
  'SET_CURRENT_HEATING',
  'SET_HEATING_REF',
  'ADD_STEP',
  'CREATE_NEW_HEATING'
]);

//Actions
export const setCurrentHeating = createAction<QueryDocumentSnapshot<Heating> | null>(
  HOME.SET_CURRENT_HEATING
);
export const addStep = createAction<Omit<Step, 'time'>>(HOME.ADD_STEP);
export const createNewHeating = createAction(HOME.CREATE_NEW_HEATING);

//Reducer
const initialState: HomeState = {
  currentHeating: {
    data: undefined,
    ref: undefined
  }
};
export default handleActions(
  {
    [HOME.SET_CURRENT_HEATING]: (state, action) =>
      update(state, {
        currentHeating: { $set: parseSnapshotToRedux(action.payload as any) }
      }),
    [HOME.SET_HEATING_REF]: (state, action) =>
      update(state, {
        currentHeating: { $merge: { ref: action.payload as any } }
      })
  },
  initialState
);

//Selectors
export const getCurrentHeating = ({ home }: ReduxState) => home.currentHeating.data;
export const getCurrentHeatingRef = ({ home }: ReduxState) => home.currentHeating.ref;

// Sagas
function* addStepSaga({ payload }: Action<Step>) {
  let heatingRef = (yield select(getCurrentHeatingRef)) as
    | firebase.firestore.DocumentReference
    | undefined;
  if (!heatingRef) {
    yield put(createNewHeating());
    yield take(HOME.SET_HEATING_REF);
    heatingRef = yield select(getCurrentHeatingRef);
  }
  payload.time = firebase.firestore.Timestamp.fromDate(new Date());
  heatingRef &&
    heatingRef.update({
      steps: firebase.firestore.FieldValue.arrayUnion(payload),
      lastModified: firebase.firestore.Timestamp.fromDate(new Date())
    });
}

function* createNewHeatingSaga() {
  const userRef = (yield select(getUserRef)) as firebase.firestore.DocumentReference | undefined;
  if (userRef) {
    const ref = (yield userRef.collection('heatings').add({
      steps: [],
      lastModified: firebase.firestore.Timestamp.fromDate(new Date())
    })) as firebase.firestore.DocumentReference;
    yield put({ type: HOME.SET_HEATING_REF, payload: ref });
  }
}

export function* homeSagas() {
  yield all([
    takeEvery(HOME.ADD_STEP, addStepSaga),
    takeEvery(HOME.CREATE_NEW_HEATING, createNewHeatingSaga)
  ]);
}
