import React from 'react';
import TemperatureInput from '../../common/components/TemperatureInput';
import { useDispatch } from 'react-redux';
import { addStep } from '../home.ducks';

interface Props {
  second?: boolean;
}

const COSteamInput: React.FC<Props> = ({ second = false }: Props) => {
  const dispatch = useDispatch();

  const handleSubmit = (temp: number | null) => {
    dispatch(
      addStep({
        phase: second ? 'finish4' : 'finish2',
        details: {
          temp
        },
        nextNotif: second
          ? { minutes: 30, instructions: 'Sauna on valmis' }
          : {
              minutes: 35,
              instructions: 'Pese lauteet'
            }
      })
    );
  };

  return (
    <div>
      {!second && <p>Hiillos oli sammunut, joten heitin häkälöylyt sekä suljin kannen, ja</p>}
      {second && <p>Heitin häkälöylyt sekä avasin kannen. Tällöin</p>}
      <TemperatureInput onSubmit={handleSubmit} />
    </div>
  );
};

export default COSteamInput;
