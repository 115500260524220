import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactChildren | React.ReactChild;
}

const ErrorText: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <Text {...rest}>{children}</Text>;
};

const Text = styled.p`
  font-size: 14px;
  color: ${theme.color.error[800]};
`;

export default ErrorText;
