import { createAction, handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { createTypes, parseSnapshotToRedux } from '../common/utils/redux.util';
import { ReduxState } from '../store';

export interface AppState {
  user: FirestoreObject<User | undefined>;
}

// Action types
export const APP = createTypes('APP', ['SET_USER']);

// Actions
export const setUser = createAction<QueryDocumentSnapshot<User> | null>(APP.SET_USER);

//Reducer
const initialState: AppState = {
  user: {
    data: undefined,
    ref: undefined
  }
};
export default handleActions(
  {
    [APP.SET_USER]: (state, { payload }: { payload: QueryDocumentSnapshot<User> | null }) =>
      update(state, {
        user: {
          $set: parseSnapshotToRedux(payload)
        }
      })
  },
  initialState
);

// Selectors
export const getUser = ({ app }: ReduxState) => app.user.data;
export const getUserRef = ({ app }: ReduxState) => app.user.ref;
