import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { addStep } from '../home.ducks';
import TemperatureInput from '../../common/components/TemperatureInput';

interface Props {
  latestTemp: number | null;
}

const AddWoodInput: React.FC<Props> = ({ latestTemp }: Props) => {
  const dispatch = useDispatch();

  const handleSubmit = (phase: Phase, instructions: string) => (temp: number | null) => {
    dispatch(
      addStep({
        phase,
        details: {
          temp
        },
        nextNotif: {
          minutes: 35,
          instructions:
            instructions + (phase === 'add' && temp && temp >= 80 ? ' tai kohenna tulipesää' : '')
        }
      })
    );
  };

  return (
    <div>
      <p>Lisäsin pesällisen ja</p>
      <TemperatureInput onSubmit={handleSubmit('add', 'Lisää puita')} />
      {latestTemp && latestTemp >= 80 ? (
        <>
          <Divider />
          <p>Kohensin tulipesää ja</p>
          <TemperatureInput
            onSubmit={handleSubmit('finish1', 'Heitä häkälöylyt ja kansi kiinni')}
          />
        </>
      ) : null}
    </div>
  );
};

const Divider = styled.hr`
  margin: 20px;
  border: none;
  border-bottom: 1px solid currentColor;
`;

export default AddWoodInput;
