import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/auth';
import { setUser, getUser } from '../app/app.ducks';
import theme from '../theme';
import gLogo from '../common/assets/google-favicon-logo.png';
import logo from '../common/assets/logo.svg';
import { firestore } from '../firebase';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const loginUser = useCallback(
    (user: firebase.User) => {
      const userObj: User = { name: user.displayName || '', messageToken: null };
      firestore
        .doc(`/users/${user.uid}`)
        .set(userObj, { merge: true })
        .then(() => firestore.doc(`/users/${user.uid}`).get())
        .then(snapshot => dispatch(setUser(snapshot as QueryDocumentSnapshot<User>)));
    },
    [dispatch]
  );

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          loginUser(user);
        } else {
          dispatch(setUser(null));
        }
      }),
    [dispatch, loginUser]
  );

  const handleSignInClick = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(result => {
        if (result && result.user) {
          loginUser(result.user);
        } else {
          dispatch(setUser(null));
        }
      });
  };

  return (
    <Container>
      <SaunaLogo src={logo} alt="Sauna logo" />
      <SigninButton onClick={handleSignInClick} visible={user !== undefined}>
        <GLogoImg src={gLogo} alt="Google logo" />
        Kirjaudu sisään
      </SigninButton>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SaunaLogo = styled.img`
  height: 250px;
  margin-bottom: 15vh;
  margin-top: 10vh;
`;

const GLogoImg = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 15px;
`;

const SigninButton = styled.button<{ visible: boolean }>`
  background: ${theme.color.gray[100]};
  padding: 10px 30px 10px 10px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 18px;
  box-shadow: 0 0 8px ${theme.color.primary[500]};
  transition: opacity 0.2s;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

export default Login;
