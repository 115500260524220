import React from 'react';
import TemperatureInput from '../../common/components/TemperatureInput';
import { useDispatch } from 'react-redux';
import { addStep } from '../home.ducks';

const WashInput: React.FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = (temp: number | null) => {
    dispatch(
      addStep({
        phase: 'finish3',
        details: {
          temp
        },
        nextNotif: null
      })
    );
  };

  return (
    <div>
      <p>Pesin lauteet ja</p>
      <TemperatureInput onSubmit={handleSubmit} />
    </div>
  );
};

export default WashInput;
