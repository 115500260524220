const theme = {
  color: {
    primary: {
      500: '#F2600C'
    },
    gray: {
      100: '#F2F2F2',
      500: '#808080',
      600: '#666666',
      700: '#333333',
      800: '#171717',
      900: '#000000'
    },
    error: {
      800: '#3d0900'
    }
  }
};

export default theme;
