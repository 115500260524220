import React from 'react';
import { useSelector } from 'react-redux';
import Home from '../home';
import Login from '../login';
import { getUser } from './app.ducks';

const App: React.FC = () => {
  const user = useSelector(getUser);

  return user ? <Home /> : <Login />;
};

export default App;
