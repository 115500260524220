import React from 'react';
import { useDispatch } from 'react-redux';
import { addStep } from '../../home/home.ducks';
import CheckButton from '../../common/components/CheckButton';

const StartHeatingInput: React.FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      addStep({
        phase: 'start',
        nextNotif: {
          minutes: 35,
          instructions: 'Lisää puita'
        }
      })
    );
  };

  return (
    <div>
      <p>Sytytin tulet kiukaaseen</p>
      <CheckButton onClick={handleSubmit} />
    </div>
  );
};

export default StartHeatingInput;
