import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';

interface Props {
  children: React.ReactChildren | React.ReactChildren | React.ReactElement;
}

const Providers: React.FC<Props> = ({ children }: Props) => {
  const store = configureStore();
  return <Provider store={store}>{children}</Provider>;
};

export default Providers;
