import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getUserRef } from '../app/app.ducks';
import ActionInput from './ActionInput';
import { setCurrentHeating } from './home.ducks';
import { messaging } from '../firebase';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const userRef = useSelector(getUserRef);

  // check notification permission
  const getToken = useCallback(() => {
    messaging.getToken().then(token => {
      if (!token) {
        Notification.requestPermission();
        userRef && userRef.update({ messageToken: null });
      } else {
        userRef && userRef.update({ messageToken: token });
      }
    });
  }, [userRef]);
  useEffect(() => {
    getToken();
    return messaging.onTokenRefresh(getToken);
  }, [getToken]);

  // subscribe for current heating
  useEffect(
    () =>
      userRef &&
      userRef
        .collection('heatings')
        .orderBy('lastModified', 'desc')
        .limit(1)
        .onSnapshot(snapshot => {
          if (snapshot.size === 1) {
            const heating = snapshot.docs[0] as QueryDocumentSnapshot<Heating>;
            const timeDiff = new Date().getTime() / 1000 - heating.data().lastModified.seconds;
            // create new heating if latest is too old
            if (timeDiff <= 6 * 60 * 60) return dispatch(setCurrentHeating(heating));
          }
          dispatch(setCurrentHeating(null));
        }),
    [userRef, dispatch]
  );

  return (
    <Container>
      <StyledActionInput />
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledActionInput = styled(ActionInput)`
  margin-top: 10vh;
  margin-bottom: 10vh;
`;

export default Home;
