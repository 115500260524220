import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckLogo } from '../assets/check.svg';
import theme from '../../theme';

const CheckButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({ ...rest }) => {
  return (
    <Button {...rest}>
      <StyledCheckLogo />
    </Button>
  );
};

const Button = styled.button`
  background: none;
  border: none;
  margin-top: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  padding: 5px;
  background: ${theme.color.gray[500]};
  transition: all 0.2s;
  outline: none;

  &:active {
    box-shadow: none;
    transform: translateY(1px);
  }
`;

const StyledCheckLogo = styled(CheckLogo)`
  display: block;
`;

export default CheckButton;
