import React, { useState } from 'react';
import styled from 'styled-components';

import NumberInput from './NumberInput';
import ErrorText from './ErrorText';
import CheckButton from './CheckButton';

interface Props {
  onSubmit(temp: number | null): void;
}

const TemperatureInput: React.FC<Props> = ({ onSubmit }: Props) => {
  const [invalidTemp, setInvalidTemp] = useState(false);
  const [temperature, setTemperature] = useState('');

  const handleSubmit = () => {
    const temp = Number.parseFloat(temperature || '');
    if (((temp !== 0 && !temp) || !Number.isFinite(temp)) && temperature !== '') {
      setInvalidTemp(true);
    } else {
      onSubmit(temperature === '' ? null : temp);
      setInvalidTemp(false);
      setTemperature('');
    }
  };

  return (
    <Wrapper>
      <Text>lämpötila oli</Text>
      <StyledNumberInput value={temperature} onChange={value => setTemperature(value)} />
      <Text>°C</Text>
      {invalidTemp && <StyledErrorText>Virheellinen lämpötila</StyledErrorText>}
      <div>
        <CheckButton onClick={handleSubmit} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Text = styled.p`
  display: inline-block;
`;

const StyledNumberInput = styled(NumberInput)`
  display: inline-block;
  margin: 0 10px;
  width: 40px;
  text-align: center;
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 10px;
`;

export default TemperatureInput;
